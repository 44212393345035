/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

function getUserName(userSession) {
  if (!userSession) return null

  if (userSession.idToken && userSession.idToken.payload) {
    if (userSession.idToken.payload.name) {
      return userSession.idToken.payload.name
    }
    if (userSession.idToken.payload.given_name) {
      return userSession.idToken.payload.given_name
    }
    if (userSession.idToken.payload['cognito:username']) {
      return userSession.idToken.payload['cognito:username']
    }
  }
  return userSession.username
}

function getUserId(userSession = {}) {
  if (userSession.idToken && userSession.idToken.payload) {
    return userSession.idToken.payload.sub
  }
}

function getAccounts(userSession) {
  if (!userSession) return null

  if (userSession.idToken && userSession.idToken.payload) {
    const accountString = userSession.idToken.payload['custom:accountId']
    if (accountString) {
      return accountString.split(',')
    }
    return null
  }
  return null
}

function getAccountId(userSession) {
  const accounts = getAccounts(userSession)
  if (accounts) return accounts[0]
  return null
}

function getAccountLocked(userSession) {
  const accountId = getAccountId(userSession)
  return accountId && accountId.length > 0
}

function getGroups(userSession) {
  if (!userSession) return null

  if (userSession.idToken && userSession.idToken.payload) {
    return userSession.idToken.payload['cognito:groups']
  }
  return null
}

function getUserOptins(userSession) {
  if (userSession.idToken && userSession.idToken.payload) {
    const userOptIns = userSession.idToken.payload['custom:optIns']
    if (userOptIns) {
      return JSON.parse(userOptIns)
    }
    return null
  }
  return null
}

export const updateUser = (userSession) => {
  return {
    type: 'UPDATE_USER',
    userId: getUserId(userSession),
    username: getUserName(userSession),
    groups: getGroups(userSession),
    accountId: getAccountId(userSession),
    accounts: getAccounts(userSession),
    accountLocked: getAccountLocked(userSession),
    optIns: getUserOptins(userSession),
  }
}

export const updateAccountId = (accountId) => ({
  type: 'UPDATE_SELECTED_ACCOUNT',
  accountId,
})

export const updatePageTitle = (title) => ({
  type: 'UPDATE_PAGE_TITLE',
  title,
})

export const updatePageSettings = (settings) => ({
  type: 'UPDATE_PAGE_SETTINGS',
  settings,
})

export const drawerToggle = () => ({
  type: 'DRAWER_TOGGLE',
})

export const updateAppMode = (mode) => ({
  type: 'UPDATE_APP_MODE',
  mode,
})
