import { FC, PropsWithChildren } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { PrimaryAppBar } from './PrimaryAppBar'
import { PrimaryNav } from './PrimaryNav'
import { MenuItem } from './interfaces'

const useStyles = makeStyles((theme: Theme) => ({
  appLayout: {
    display: 'flex',
    width: '100%',
  },
  navContainer: {},
  contentContainer: {
    flex: 1,
  },
  content: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  searchInput: {
    transition: theme.transitions.create('width'),
  },
}))

export const AppLayout: FC<PropsWithChildren<Props>> = (props) => {
  const { children, onStateChange: handleStateChange, menuItems, drawerWidth, info, appBarColor } = props

  const classes = useStyles(props)

  return (
    <div className={classes.appLayout}>
      <PrimaryNav
        info={info}
        drawerWidth={drawerWidth || 200}
        menuItems={menuItems}
        onStateChange={handleStateChange}
      />
      <div className={classes.contentContainer}>
        <PrimaryAppBar drawerWidth={drawerWidth || 200} appBarColor={appBarColor} />
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  )
}

interface Props {
  info?: string
  drawerWidth?: number
  menuItems: MenuItem[]
  onStateChange?: any
  appBarColor?: string
}
