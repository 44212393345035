/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

const appSettings = (
  state = {
    drawerOpen: true,
    mode: 'default',
  },
  action,
) => {
  switch (action.type) {
    case 'DRAWER_TOGGLE':
      return {
        drawerOpen: !state.drawerOpen,
        mode: state.mode,
      }
    case 'UPDATE_APP_MODE':
      return {
        drawerOpen: state.drawerOpen,
        mode: action.mode,
      }
    default:
      return state
  }
}

export default appSettings
