/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

const page = (state = { title: 'DML', actions: [] }, action) => {
  switch (action.type) {
    case 'UPDATE_PAGE_TITLE':
      return {
        title: action.title,
        actions: [],
      }
    case 'UPDATE_PAGE_SETTINGS':
      return {
        breadcrumb: action.settings.breadcrumb,
        actions: action.settings.actions || [],
      }
    default:
      return state
  }
}

export default page
