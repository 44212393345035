import apiOperator from '@dmlab/ui-api-operator'
import type {
  AccountAccessRequest,
  AccountAccessRequestCreationPayload
} from '@dmlab/lambda-dao-account-access-requests'
import { AxiosRequestConfig } from 'axios'
import defaultsDeep from 'lodash/defaultsDeep'

const basePath = '/request-access'

export const requestAccess = (data: AccountAccessRequestCreationPayload, opts: AxiosRequestConfig = {}) =>
  apiOperator<void, void>(
    defaultsDeep(opts, {
      method: 'post',
      url: basePath,
      useAuth: false,
      data,
    }),
  )

export { AccountAccessRequestCreationPayload, AccountAccessRequest }
