/**
 * Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'
import Layout from './Layout'
import SignIn from './SignIn'
import ForgotPassword from './ForgotPassword'
import Register from './Register'
import ConfirmSignUp from './ConfirmSignUp'
import RequireNewPassword from './RequireNewPassword'
import VerifyContact from './VerifyContact'
import ConfirmSignIn from './ConfirmSignIn'
import MarketplaceRequestAccess from './MarketplaceRequestAccess'
import { validAuthStates } from './propTypes'

const AuthHead = (props) => {
  const {
    authState,
    onStateChange: handleStateChange,
    registrationLogoPath,
    name,
    description,
    inviteToken,
    authData,
    emailContact,
    isRegister,
    useEmail,
    marketplaceId,
    marketplaceRequestAccess,
    isPubHub,
  } = props

  const authPartProps = {
    authState,
    onStateChange: handleStateChange,
    authData,
    inviteToken,
    useEmail,
    marketplaceId,
  }

  if (authState === 'signedIn') return null

  return (
    <Layout
      registrationLogoPath={registrationLogoPath}
      name={name}
      authState={authState}
      description={description}
      emailContact={emailContact}
      marketplaceRequestAccess={marketplaceRequestAccess}
      onStateChange={handleStateChange}
      isPubHub={isPubHub}
    >
      {!validAuthStates.includes(authState) && (
        <Alert severity="error">
          Oops, there is an issue on our side. Please contact support if this issue persists.
        </Alert>
      )}
      {['signIn', 'signedOut', 'signedUp', 'passwordUpdated'].includes(authState) && <SignIn {...authPartProps} />}
      {['forgotPassword', 'passwordReset'].includes(authState) && <ForgotPassword {...authPartProps} />}
      {isRegister && ['signUp'].includes(authState) && <Register {...authPartProps} />}
      {['confirmSignUp'].includes(authState) && <ConfirmSignUp {...authPartProps} />}
      {['requireNewPassword'].includes(authState) && <RequireNewPassword {...authPartProps} />}
      {['verifyContact'].includes(authState) && <VerifyContact {...authPartProps} />}
      {['confirmSignIn'].includes(authState) && <ConfirmSignIn {...authPartProps} />}
      {['marketplaceRequestAccess'].includes(authState) && <MarketplaceRequestAccess {...authPartProps} />}
    </Layout>
  )
}

AuthHead.defaultProps = {
  social: {},
  useEmail: true,
}

AuthHead.propTypes = {
  authData: PropTypes.shape({}),
  onStateChange: PropTypes.func.isRequired,
  inviteToken: PropTypes.string,
  authState: PropTypes.oneOf(validAuthStates),
  registrationLogoPath: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  emailContact: PropTypes.string,
  isRegister: PropTypes.bool,
  useEmail: PropTypes.bool,
  social: PropTypes.shape({
    twitter: PropTypes.string,
    linkedin: PropTypes.string,
    facebook: PropTypes.string,
    youtube: PropTypes.string,
    instagram: PropTypes.string,
  }),
  marketplaceRequestAccess: PropTypes.bool,
  isPubHub: PropTypes.bool,
}

export default AuthHead
