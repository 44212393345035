/**
 * @file Confirm Sign In Login View
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import { Auth } from '@aws-amplify/auth'
import cx from 'classnames'
import React, { useState, useMemo } from 'react'
import { I18n, isEmpty } from '@aws-amplify/core'
import Alert from '@material-ui/lab/Alert'
import { TextField, Button, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import propTypes from './propTypes'

const useStyles = makeStyles((theme) => ({
  confirmSignIn: {
    display: 'flex',
    flexDirection: 'column',
  },
  resendCode: {
    background: 'none',
    border: 'none',
    outline: 'none',
    color: theme.palette.primary.main,
    fontSize: 'inherit',
    marginLeft: theme.spacing(0.5),
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  confirmButton: {
    margin: theme.spacing(1, 0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
}))

const ConfirmSignIn = (props) => {
  const {
    onStateChange: handleStateChange,
    authData: { user: userFromAuthData, username },
  } = props

  const mfaType = useMemo(
    () => (userFromAuthData.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : 'SMS_MFA'),
    [userFromAuthData],
  )
  const classes = useStyles(props)
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [limitReached, setLimitReached] = useState(false)

  const handleResendCode = async () => {
    setLoading(true)
    try {
      await Auth.resendSignUp(username)
    } catch (e) {
      console.error(e)
      if (e.code === 'LimitExceededException') {
        setLimitReached(true)
      }
      setError(e.message || 'Oops, there was an issue resending your verification code.')
    }
    setLoading(false)
  }

  const handleBackToSignIn = () => {
    handleStateChange('signIn', { username })
  }

  const checkContact = async (user) => {
    const verifiedContactRes = await Auth.verifiedContact(user)
    if (!isEmpty(verifiedContactRes.verified)) {
      handleStateChange('signedIn', user)
    } else {
      handleStateChange('verifyContact', Object.assign(user, verifiedContactRes))
    }
  }

  const handleConfirm = async () => {
    try {
      const user = await Auth.confirmSignIn(userFromAuthData, code, mfaType)
      await checkContact(user)
    } catch (e) {
      console.error(e)
      setError(e.message || `Oops, there was an issue confirming your verification code.`)
      setLoading(false)
    }
  }

  const handleChange = ({ target: { value } }) => {
    setCode(value)
  }

  return (
    <div className={cx(classes.confirmSignIn, 'animate__animated animate__fadeIn animate__faster')}>
      {!error && (
        <Alert className={classes.alert} severity="info" icon={false}>
          {I18n.get(`Confirm verification Code`)}
        </Alert>
      )}
      {!!error && (
        <Alert className={cx(classes.alert, 'animate__animated animate__headShake')} severity="error">
          {error}
        </Alert>
      )}
      <TextField
        label="Code"
        variant="outlined"
        name="code"
        value={code}
        onChange={handleChange}
        fullWidth
        disabled={limitReached || isLoading}
        size="small"
        helperText={
          <span>
            Didn't receive a verification code?
            <button className={classes.resendCode} onClick={handleResendCode}>
              Resend Code
            </button>
          </span>
        }
      />
      <Button
        type="submit"
        color="primary"
        variant="contained"
        onClick={handleConfirm}
        fullWidth
        disabled={limitReached || isLoading || code.length < 1}
        className={classes.confirmButton}
      >
        Confirm Code
      </Button>
      <Button variant="outlined" fullWidth onClick={handleBackToSignIn} disabled={isLoading}>
        Back To Sign In
      </Button>
      {isLoading && (
        <LinearProgress className={cx(classes.progress, 'animate__animated animate__fadeIn animate__faster')} />
      )}
    </div>
  )
}

ConfirmSignIn.propTypes = propTypes

export default ConfirmSignIn
