import React, { FC, createContext, useState, useCallback, useEffect, PropsWithChildren } from 'react'
import { AppLayoutContextInterface } from './interfaces'

export const AppLayoutContext = createContext<AppLayoutContextInterface>({})

export const AppLayoutProvider: FC<PropsWithChildren<Props>> = ({ children, defaultExpanded }) => {
  const [appLayoutState, setAppLayoutState] = useState({
    menuIsExpanded: defaultExpanded,
    appBarIsDense: false,
  })

  const handleScroll = useCallback(() => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop
    const goDenseOn = 64
    if (distanceY >= goDenseOn && !appLayoutState.appBarIsDense) {
      setAppLayoutState((currentAppLayoutState) => ({
        ...currentAppLayoutState,
        appBarIsDense: true,
      }))
    } else if (distanceY < goDenseOn && appLayoutState.appBarIsDense) {
      setAppLayoutState((currentAppLayoutState) => ({
        ...currentAppLayoutState,
        appBarIsDense: false,
      }))
    }
  }, [appLayoutState])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const toggleMenuIsExpanded = useCallback(() => {
    setAppLayoutState((currentAppLayoutState) => ({
      ...currentAppLayoutState,
      menuIsExpanded: !currentAppLayoutState.menuIsExpanded,
    }))
  }, [])

  const value = {
    ...appLayoutState,
    toggleMenuIsExpanded,
  }

  return <AppLayoutContext.Provider value={value}>{children}</AppLayoutContext.Provider>
}

interface Props {
  defaultExpanded: boolean
}
