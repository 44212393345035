import format from 'date-fns/format'

const standardFormatString = 'MM/dd/yy'

export const standardDateFormat = (rawDate: string | number, addTimeOfDay?: boolean): string => {
  try {
    const dateObj = new Date(rawDate)
    const formatString = addTimeOfDay ? `${standardFormatString} - hh:mm aaa` : standardFormatString
    return format(dateObj, formatString)
  } catch (e) {
    return '-'
  }
}
