/**
 * @file Amplify API Helper
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import { API as AmplifyApi } from '@aws-amplify/api'
import get from 'lodash/get'
// axios comes with amplify under the hood
import axios from 'axios'

const getBaseURL = () => get(AmplifyApi, '_restApi._options.endpoints[0].endpoint')

export const getConsumerById = (id) => {
  return axios({
    method: 'GET',
    baseURL: getBaseURL(),
    url: `/user-invites/${id}/consume`,
  })
}

export const consumeUserInvite = (id, userId) => {
  return axios({
    method: 'PATCH',
    baseURL: getBaseURL(),
    url: `/user-invites/${id}/consume`,
    data: { userId },
  })
}
