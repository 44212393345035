/**
 * Copyright 2021-present, Distributed Media Lab, Inc.
 */

import PropTypes from 'prop-types'

export const validAuthStates = [
  'signIn',
  'signedOut',
  'signedUp',
  'forgotPassword',
  'passwordUpdated',
  'signUp',
  'confirmSignUp',
  'passwordReset',
  'requireNewPassword',
  'verifyContact',
  'confirmSignIn',
  'marketplaceRequestAccess',
]

export default {
  authData: PropTypes.shape({}),
  onStateChange: PropTypes.func.isRequired,
  inviteToken: PropTypes.string,
  authState: PropTypes.oneOf(validAuthStates),
  useEmail: PropTypes.bool,
}
