import React, { FC, useCallback, MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { Breadcrumbs, Link, Typography, Button, Theme, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(1, 0, 2, 0),
  },
  breadcrumbText: {
    fontStyle: 'normal',
  },
  actions: {
    display: 'flex',
  },
  actionButton: {
    marginLeft: theme.spacing(0.5),
    '&:first-child': {
      marginLeft: 0,
    },
  },
}))

export const AppBreadcrumbs: FC<Props> = ({ breadcrumbs, actions }) => {
  const history = useHistory()
  const classes = useStyles()

  const handleClick = useCallback((link: string) => history.push(link), [history])

  return (
    <div className={classes.flexContainer}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map(({ label, link }, i) =>
          link ? (
            <Link
              className={classes.breadcrumbText}
              variant="h6"
              key={`${i}_${link}`}
              color="inherit"
              href={link}
              onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault()
                handleClick(link)
              }}
            >
              {label}
            </Link>
          ) : (
            <Typography key={`${i}_${label}`} className={classes.breadcrumbText} variant="h6" color="textPrimary">
              {label}
            </Typography>
          ),
        )}
      </Breadcrumbs>
      {Array.isArray(actions) && actions.length > 0 && (
        <div className={classes.actions}>
          {actions.map(({ label, onClick: handleClick }, i) => (
            <Button
              key={`${i}_${label}`}
              className={classes.actionButton}
              color="primary"
              variant="contained"
              onClick={handleClick}
            >
              {label}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

interface Props {
  breadcrumbs: { label: string; link?: string }[]
  actions?: { label: string; onClick: any }[]
}
