import { FC, createContext, useState, useMemo, useCallback, SetStateAction, Dispatch } from 'react'
import { GridSortModel } from '@material-ui/data-grid'

export const ListView = createContext<IListView>({} as IListView)

export const ListViewProvider: FC = ({ children }) => {
  const [sortModelMap, setSortModelMap] = useState<SortModelMap>({})
  const [paginationMap, setPaginationMap] = useState<PaginationMap>({})

  const handleSortModelChange = useCallback<IListView['handleSortModelChange']>((entityKey, gridSortModel) => {
    setSortModelMap((currentSortModelMap) => ({
      ...currentSortModelMap,
      [entityKey]: gridSortModel,
    }))
  }, [])

  const handleRowsPerPageChange = useCallback<IListView['handleRowsPerPageChange']>((entityKey, newRowsPerPage) => {
    setPaginationMap((currentPaginationMap) => ({
      ...currentPaginationMap,
      [entityKey]: {
        ...currentPaginationMap[entityKey],
        limit: newRowsPerPage,
      },
    }))
  }, [])

  const handlePageChange = useCallback<IListView['handlePageChange']>((entityKey, newPage) => {
    setPaginationMap((currentPaginationMap) => ({
      ...currentPaginationMap,
      [entityKey]: {
        ...currentPaginationMap[entityKey],
        currentPage: newPage,
      },
    }))
  }, [])

  const resetEntityToDefault = useCallback(
    (entityKey: string, shouldResetSort?: boolean) => {
      shouldResetSort && handleSortModelChange(entityKey, [{ field: 'updatedAt', sort: 'desc' }])
      handleRowsPerPageChange(entityKey, 20)
      handlePageChange(entityKey, 0)
    },
    [handlePageChange, handleRowsPerPageChange, handleSortModelChange],
  )

  const value = useMemo(
    () => ({
      paginationMap,
      sortModelMap,
      handleSortModelChange,
      setSortModelMap,
      setPaginationMap,
      handleRowsPerPageChange,
      handlePageChange,
      resetEntityToDefault,
    }),
    [
      sortModelMap,
      handleSortModelChange,
      setSortModelMap,
      paginationMap,
      setPaginationMap,
      handleRowsPerPageChange,
      handlePageChange,
      resetEntityToDefault,
    ],
  )
  return <ListView.Provider value={value}>{children}</ListView.Provider>
}

export interface PaginationValues {
  limit: number
  currentPage: number
}
export type SortModelMap = Record<string, GridSortModel>
export type PaginationMap = Record<string, PaginationValues>
export interface IListView {
  sortModelMap: SortModelMap
  paginationMap: PaginationMap
  handleSortModelChange: (entityKey: string, gridSortModel: GridSortModel) => void
  setSortModelMap: Dispatch<SetStateAction<SortModelMap>>
  setPaginationMap: Dispatch<SetStateAction<PaginationMap>>
  handleRowsPerPageChange: (entityKey: string, newRowsPerPage: number) => void
  handlePageChange: (entityKey: string, newPage: number) => void
  resetEntityToDefault: (entityKey: string) => void
}
