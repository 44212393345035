/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

const userIdentity = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        userId: action.userId,
        username: action.username,
        groups: action.groups,
        accountId: action.accountId,
        accounts: action.accounts,
        accountLocked: action.accountLocked,
        isDev: action.groups && action.groups.includes('Developer'),
        optIns: action.optIns,
      }
    default:
      return state
  }
}

export default userIdentity
