import styled from 'styled-components'

export const DataGridOuterContainer = styled.div`
  /* Enable this to make the grid fit on the screen and scroll-able inside */
  /* height: 60vh; */
  width: 100%;
`

export const DataGridInnerContainer = styled.div`
  display: flex;
  height: 100%;
  & .MuiDataGrid-iconSeparator {
    display: none;
  }
  & .MuiDataGrid-columnHeaderTitle {
    overflow: unset;
    text-overflow: unset;
    white-space: break-spaces;
    line-height: 1.5;
  }
  & .MuiDataGrid-columnsContainer {
    flex-direction: row;
  }
  & .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 !important;
  }
`

export const AddButtonWrapper = styled.div`
  padding: 16px;
`
