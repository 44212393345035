import { FC, useContext, MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import { AppBar, Toolbar, IconButton, Theme, makeStyles } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { PrimaryWhiteLogoDml } from '@dmlab/ui-dml-assets'

import { AppLayoutContext } from './AppLayoutProvider'

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    color: '#333',
    backgroundColor: (props) => props.appBarColor || theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: ({ drawerWidth }: Props) => ({
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  fixedSpacing: {
    height: theme.spacing(8),
    width: '100%',
    opacity: 0,
    display: 'block',
  },
  toolbarRoot: {
    transition: theme.transitions.create('min-height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  notificationsContainer: {
    paddingLeft: theme.spacing(1),
    textAlign: 'right',
  },
  logo: {
    height: theme.spacing(4),
    width: 'auto',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    cursor: 'pointer',
  },
  logoShift: {
    height: theme.spacing(3.5),
  },
  menuButtonContainer: {
    flex: 1,
  },
  menuIcon: {
    color: '#fff',
  },
  logoContainer: {
    display: 'flex',
  },
  rightAppBarContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  menuIconButton: {
    paddingLeft: 0,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
}))

export const PrimaryAppBar: FC<Props> = (props) => {
  const classes = useStyles(props)
  const appLayout = useContext(AppLayoutContext)
  const history = useHistory()

  const { toggleMenuIsExpanded: handleToggleMenu, menuIsExpanded, appBarIsDense } = appLayout

  const handleGoHome = (e: MouseEvent<SVGSVGElement>) => {
    e.preventDefault()
    history.push('/')
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <div className={classes.fixedSpacing} />
      <AppBar
        position="fixed"
        className={cx(classes.appBar, {
          [classes.appBarShift]: menuIsExpanded,
        })}
      >
        <Toolbar variant={appBarIsDense ? 'dense' : 'regular'} classes={{ root: classes.toolbarRoot }}>
          <div className={classes.menuButtonContainer}>
            {!menuIsExpanded && (
              <IconButton
                className={classes.menuIconButton}
                onClick={handleToggleMenu}
                size={appBarIsDense ? 'small' : 'medium'}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            )}
          </div>

          <div className={classes.logoContainer}>
            <PrimaryWhiteLogoDml
              title="Distributed Media Lab"
              onClick={handleGoHome}
              className={cx(classes.logo, {
                [classes.logoShift]: appBarIsDense,
              })}
            />
          </div>

          <div className={classes.rightAppBarContainer} />
        </Toolbar>
      </AppBar>
    </div>
  )
}

interface Props {
  drawerWidth: number
  appBarColor?: string
}
