/**
 * @file Verify Contact Login View
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { useState } from 'react'
import cx from 'classnames'
import Alert from '@material-ui/lab/Alert'
import { Auth } from '@aws-amplify/auth'
import { Button, LinearProgress, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import propTypes from './propTypes'

const useStyles = makeStyles((theme) => ({
  verifyContact: {
    display: 'flex',
    flexDirection: 'column',
  },
  confirmButton: {
    margin: theme.spacing(1, 0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  chooseAtrBtn: {
    marginBottom: theme.spacing(1),
  },
  backToSignIn: {
    marginTop: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
}))

const VerifyContact = (props) => {
  const { authData, onStateChange: handleStateChange } = props

  const classes = useStyles(props)

  const { unverified = {} } = authData
  const { email, phone_number: phoneNumber } = unverified

  const [isLoading, setLoading] = useState(false)
  const [verifyAttr, setVerifyAttr] = useState(null)
  const [error, setError] = useState(null)
  const [code, setCode] = useState('')

  const handleCodeChange = ({ target: { value } }) => {
    setCode(value)
  }

  const handleBackToSignIn = () => {
    handleStateChange('signIn', { username: email })
  }

  const handleVerifyCurrentUserAttribute = async (v) => {
    setLoading(true)
    try {
      await Auth.verifyCurrentUserAttribute(v)
      setVerifyAttr(v)
    } catch (e) {
      console.error(e)
      setError(e)
    }
    setLoading(false)
  }

  const handleVerifyCurrentUserAttributeSubmit = async () => {
    setLoading(true)
    try {
      await Auth.verifyCurrentUserAttributeSubmit(verifyAttr, code)
      handleStateChange('signedIn', authData)
    } catch (e) {
      console.error(e)
      setError(e)
    }
  }

  return (
    <div className={cx(classes.verifyContact, 'animate__animated animate__fadeIn animate__faster')}>
      {!!error && (
        <Alert className={cx(classes.alert, 'animate__animated animate__headShake')} severity="error">
          {error}
        </Alert>
      )}
      {!unverified && (
        <Alert severity="warning" className={classes.alert}>
          This user has already been verified.
        </Alert>
      )}
      {unverified && !error && (
        <Alert severity="info" icon={false} className={classes.alert}>
          You must verify your account before proceeding.
        </Alert>
      )}
      {!verifyAttr && (
        <>
          {email && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => handleVerifyCurrentUserAttribute('email')}
              className={classes.chooseAtrBtn}
            >
              Verify with Email
            </Button>
          )}
          {phoneNumber && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => handleVerifyCurrentUserAttribute('phone_number')}
            >
              Verify with Phone Number
            </Button>
          )}
        </>
      )}
      {verifyAttr && (
        <>
          <TextField
            label="Code"
            variant="outlined"
            name="code"
            value={code}
            onChange={handleCodeChange}
            fullWidth
            margin="dense"
            disabled={isLoading}
            size="small"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleVerifyCurrentUserAttributeSubmit}
          >
            Verify Account
          </Button>
        </>
      )}
      <Button
        variant="outlined"
        fullWidth
        onClick={handleBackToSignIn}
        className={classes.backToSignIn}
        disabled={isLoading}
      >
        Back To Sign In
      </Button>
      {isLoading && (
        <LinearProgress className={cx(classes.progress, 'animate__animated animate__fadeIn animate__faster')} />
      )}
    </div>
  )
}

VerifyContact.propTypes = propTypes

export default VerifyContact
