import React, { FC, useContext, useState, useCallback, MouseEvent, HTMLAttributeAnchorTarget } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  IconButton,
  Divider,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Toolbar,
  Collapse,
  Tooltip,
  Typography,
  Theme,
  makeStyles,
} from '@material-ui/core'
import cx from 'classnames'
import { Auth } from '@aws-amplify/auth'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { AppLayoutContext } from './AppLayoutProvider'
import { MenuItem } from './interfaces'

const abbreviate = (str: string): string => {
  const parsedStr = str.replace(/[^\w\s]/gi, '').toUpperCase()
  const split = parsedStr.split(' ')
  return split.length === 1 ? parsedStr.substring(0, 2) : `${split[0].substring(0, 1)}${split[1].substring(0, 1)}`
}

const useStyles = makeStyles((theme: Theme) => ({
  childLabelCollapsed: {
    textAlign: 'center',
    '& > span': {
      fontWeight: 500,
    },
  },
  info: {
    flex: 1,
    paddingLeft: theme.spacing(1.5),
  },
  drawer: {
    width: 'auto',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: ({ drawerWidth }: Props) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    transition: theme.transitions.create('min-height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  toolbarDense: {
    minHeight: '46px',
  },
  primaryList: {
    paddingTop: 0,
    flex: 1,
  },
  menuIcon: {
    paddingLeft: theme.spacing(1),
  },
  closeMenuIcon: {
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  collapseWrapper: {
    '& > div:first-child': {
      borderTop: '1px solid rgb(146 146 146 / 22%)',
    },
    '& > div:last-child': {
      borderBottom: '1px solid rgb(146 146 146 / 22%)',
    },
  },
}))

export const PrimaryNav: FC<Props> = (props) => {
  const { onStateChange, menuItems, info } = props
  const appLayout = useContext(AppLayoutContext)
  const history = useHistory()
  const queryClient = useQueryClient()
  const classes = useStyles(props)
  const [activeParent, setActiveParent] = useState(null)

  const {
    location: { pathname },
  } = history

  const { toggleMenuIsExpanded: handleToggleMenu, menuIsExpanded, appBarIsDense } = appLayout

  const handleToggleActiveParent = useCallback((i) => {
    setActiveParent((current) => (current && current === i ? null : i))
  }, [])

  const handleSignOut = async () => {
    try {
      await Auth.signOut()
      queryClient.clear()
      onStateChange('signedOut', {})
    } catch (e) {
      console.error('error logging user out', e)
    }
  }

  const handleIsSelected = useCallback(
    (navItem: Partial<MenuItem>) => {
      if (navItem.to === pathname) return true
      if (navItem.children && navItem.children.some(({ to }) => pathname === to)) return true
      return false
    },
    [pathname],
  )

  const handleGoTo = (path: string) => (e: MouseEvent<HTMLAnchorElement>) => {
    if (e.isDefaultPrevented() || e.metaKey || e.ctrlKey) return
    e.preventDefault()
    history.push(path)
  }

  const handleGoToLink = useCallback(
    (path: string, target?: HTMLAttributeAnchorTarget) => () => {
      window.open(path, target)
    },
    [],
  )

  const handleGoToWithChildren = (parentNavItem: MenuItem, index: number) => (e: MouseEvent<HTMLAnchorElement>) => {
    if (e.isDefaultPrevented() || e.metaKey || e.ctrlKey) return
    e.preventDefault()
    parentNavItem.children && handleToggleActiveParent(index)
    if (parentNavItem.to) history.push(parentNavItem.to)
  }

  return (
    <Drawer
      variant="permanent"
      className={cx(classes.drawer, {
        [classes.drawerOpen]: menuIsExpanded,
        [classes.drawerClose]: !menuIsExpanded,
      })}
      classes={{
        paper: cx({
          [classes.drawerOpen]: menuIsExpanded,
          [classes.drawerClose]: !menuIsExpanded,
        }),
      }}
    >
      <Toolbar variant={appBarIsDense ? 'dense' : 'regular'} className={classes.toolbar}>
        {info && <Typography className={classes.info}>{info}</Typography>}
        <IconButton
          className={classes.closeMenuIcon}
          onClick={handleToggleMenu}
          size={appBarIsDense ? 'small' : 'medium'}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List className={classes.primaryList}>
        {menuItems.map((parentNavItem, i) =>
          !parentNavItem.children ? (
            <Tooltip
              key={`${i}_${parentNavItem.to}`}
              placement="right"
              disableHoverListener={menuIsExpanded}
              title={parentNavItem.label}
            >
              <ListItem
                selected={handleIsSelected(parentNavItem)}
                button
                component="a"
                href={parentNavItem.to}
                onClick={
                  parentNavItem.link
                    ? handleGoToLink(parentNavItem.link, parentNavItem.target)
                    : handleGoTo(parentNavItem.to)
                }
              >
                <ListItemIcon className={classes.menuIcon}>
                  <parentNavItem.Icon />
                </ListItemIcon>
                <ListItemText primary={parentNavItem.label} />
              </ListItem>
            </Tooltip>
          ) : (
            <React.Fragment key={`${i}_parentNavItem.to`}>
              <Tooltip placement="right" disableHoverListener={menuIsExpanded} title={parentNavItem.label}>
                <ListItem
                  selected={handleIsSelected(parentNavItem)}
                  button
                  component="a"
                  href={parentNavItem.to}
                  onClick={handleGoToWithChildren(parentNavItem, i)}
                >
                  <ListItemIcon className={classes.menuIcon}>
                    <parentNavItem.Icon />
                  </ListItemIcon>
                  <ListItemText primary={parentNavItem.label} />
                  {activeParent === i ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </Tooltip>
              <Collapse
                classes={{ wrapperInner: classes.collapseWrapper }}
                in={activeParent === i}
                timeout="auto"
                unmountOnExit
              >
                {parentNavItem.children.map((childNavItem) => (
                  <Tooltip
                    key={childNavItem.to}
                    placement="right"
                    disableHoverListener={menuIsExpanded}
                    title={childNavItem.label}
                  >
                    <ListItem
                      selected={handleIsSelected(childNavItem)}
                      button
                      component="a"
                      href={childNavItem.to}
                      onClick={handleGoTo(childNavItem.to)}
                    >
                      {menuIsExpanded && (
                        <ListItemIcon className={classes.menuIcon}>
                          <ChevronRightIcon />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        className={cx({ [classes.childLabelCollapsed]: !menuIsExpanded })}
                        primary={menuIsExpanded ? childNavItem.label : abbreviate(childNavItem.label)}
                      />
                    </ListItem>
                  </Tooltip>
                ))}
              </Collapse>
            </React.Fragment>
          ),
        )}
      </List>
      {menuIsExpanded && (
        <List className="animate__animated animate__fadeIn animate__faster">
          <ListItem button onClick={handleSignOut}>
            <ListItemIcon className={classes.menuIcon}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      )}
    </Drawer>
  )
}

interface Props {
  drawerWidth: number
  info?: string
  onStateChange: any
  menuItems: MenuItem[]
}
