import { FC, useCallback, useEffect, useContext, ElementType, MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import { Card, CardContent, CardHeader, CardProps, TypographyTypeMap } from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridRowData,
  GridValueFormatterParams,
  GridCellValue,
  GridOptions,
  GridSortModel,
} from '@material-ui/data-grid'

import { standardDateFormat } from 'utils/dates'
import { DataGridOuterContainer, DataGridInnerContainer, AddButtonWrapper } from './elements'
import { FlexContainer } from '../containers'
import { ListView } from '../Provider'

export const DataRenderer: FC<Props> = ({
  title,
  rows,
  columnDef,
  isLoading,
  errorMessage,
  AddNewButton,
  baseRoute,
  totalRowCount,
  entityKey,
  initialSortModel,
  onRowClick,
  autoHeight: autoHeightProps,
  subtitle,
  paperVariant = 'elevation',
  titleTypographyProps = {},
}) => {
  const { paginationMap, sortModelMap, handlePageChange, handleRowsPerPageChange, handleSortModelChange } =
    useContext(ListView)
  const { limit = 20, currentPage = 0 } = paginationMap[entityKey] || {}
  const sortModel = sortModelMap[entityKey]
  const autoHeight = autoHeightProps !== undefined ? autoHeightProps : true

  const { push } = useHistory()
  const handleRowClick = useCallback<Exclude<GridOptions['onRowClick'], undefined>>(
    (params) => {
      if (!baseRoute) return
      push(`${baseRoute}/show/${params.id}`)
    },
    [push, baseRoute],
  )

  const handleEntityPageChange = useCallback(
    (newPage: number) => {
      handlePageChange(entityKey, newPage)
    },
    [handlePageChange, entityKey],
  )

  const handleEntityRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      handleRowsPerPageChange(entityKey, newRowsPerPage)
    },
    [handleRowsPerPageChange, entityKey],
  )

  const handleEntitySortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      handleSortModelChange(entityKey, sortModel)
    },
    [handleSortModelChange, entityKey],
  )

  useEffect(() => {
    if (!initialSortModel || !!sortModel) return
    handleSortModelChange(entityKey, initialSortModel)
  }, [entityKey, initialSortModel, handleSortModelChange, sortModel])

  return (
    <Card variant={paperVariant}>
      <FlexContainer justify="space-between">
        <CardHeader title={title} subheader={subtitle} titleTypographyProps={{ ...titleTypographyProps }} />
        <AddButtonWrapper>{AddNewButton}</AddButtonWrapper>
      </FlexContainer>
      <CardContent>
        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
        <DataGridOuterContainer>
          <DataGridInnerContainer>
            <DataGrid
              onRowClick={onRowClick || handleRowClick}
              disableColumnSelector
              disableSelectionOnClick
              columns={columnDef}
              disableColumnMenu
              rowsPerPageOptions={[20, 50, 100]}
              rows={rows}
              loading={isLoading}
              rowCount={totalRowCount}
              paginationMode="server"
              sortModel={sortModel}
              onSortModelChange={handleEntitySortModelChange}
              onPageChange={handleEntityPageChange}
              onPageSizeChange={handleEntityRowsPerPageChange}
              pageSize={limit}
              page={currentPage}
              autoHeight={autoHeight}
              pagination
              onCellClick={(_, event) => {
                event.defaultMuiPrevented = true
                if (event.currentTarget !== event.target) {
                  // Allow for individual cells to have handlers
                  event.stopPropagation()
                }
              }}
            />
          </DataGridInnerContainer>
        </DataGridOuterContainer>
      </CardContent>
    </Card>
  )
}

export const gridDataDateParser = ({ value }: GridValueFormatterParams): GridCellValue => {
  if (!value) return '-'
  if (typeof value !== 'string' && typeof value !== 'number') return value
  return standardDateFormat(value)
}

interface Props {
  autoHeight?: boolean
  title?: string
  columnDef: GridColDef[]
  rows: GridRowData[]
  isLoading?: boolean
  errorMessage?: string
  AddNewButton?: JSX.Element
  /** This is the base part of the url that will have the ID appended to it.
   *
   * If none is provided, clicking on a row **will not** navigate anywhere.
   * @example Providing '/something' will result in the url being changed to '/something/show/12345' when a row is clicked
   */
  baseRoute?: string
  /** The max number of rows possible */
  totalRowCount: number
  /** The name of the data entity this list renders.
   * @example 'collectionAlias'
   */
  entityKey: string
  initialSortModel?: GridSortModel
  onRowClick?: GridOptions['onRowClick']
  subtitle?: string
  paperVariant?: CardProps['variant']
  titleTypographyProps?: {
    variant?: TypographyTypeMap['props']['variant']
    component?: ElementType
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  }
}

export * from './new-button'
