/**
 * Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { useCallback } from 'react'
import { Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { PrimaryLogoDml } from '@dmlab/ui-dml-assets'

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  logo: {
    marginBottom: theme.spacing(2),
    maxWidth: '500px',
  },
  content: {
    display: 'flex',
    maxWidth: '1000px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
    paddingBottom: theme.spacing(8),
  },
  form: {
    flex: '1 1 auto',
    padding: theme.spacing(1, 2),
    maxWidth: '100vw',

    // Forces minimum width on extension text fields
    [theme.breakpoints.down(480)]: {
      minWidth: '90%',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
    },
  },
  info: {
    flex: '0 0 auto',
    padding: theme.spacing(1, 2),
    maxWidth: '90vw',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '50%',
      marginTop: '2px',
    },
  },
  description: {
    marginBottom: theme.spacing(1.5),
  },
  btnLink: {
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    fontSize: 'inherit',
    fontWeight: 500,
    color: '#000dee',
    padding: 0,
    cursor: 'pointer',
    margin: 0,
    textDecoration: 'underline',
  },
}))

const Layout = (props) => {
  const {
    children,
    name,
    description,
    emailContact,
    marketplaceRequestAccess,
    onStateChange: handleStateChange,
    authState,
    isPubHub,
  } = props
  const classes = useStyles(props)

  const handleRequestAccess = useCallback(
    (e) => {
      e.preventDefault()
      handleStateChange('marketplaceRequestAccess')
    },
    [handleStateChange],
  )

  const handleRouteToLogin = useCallback(
    (e) => {
      e.preventDefault()
      handleStateChange('signIn')
    },
    [handleStateChange],
  )

  return (
    <div className={classes.layout}>
      <PrimaryLogoDml className={classes.logo} title={name} />
      <div className={classes.content}>
        <div className={classes.form}>{children}</div>
        {description && description.length > 0 && (
          <div className={classes.info}>
            {authState === 'marketplaceRequestAccess' ? (
              <>
                <Typography className={classes.description}>
                  Please use this form to request access to the DML Marketplace. Provide the requested details about
                  your company so we can process your request. You will receive an update, via email once we have
                  processed your request.
                </Typography>
                <Typography className={classes.description}>
                  If you are trying to login into the DML Marketplace please{' '}
                  <button className={classes.btnLink} onClick={handleRouteToLogin}>
                    login here
                  </button>
                  .
                </Typography>
                <Typography className={classes.description}>
                  Explore our plan options on our{' '}
                  <Link
                    className={classes.btnLink}
                    href="https://www.distributedmedialab.com/plans-and-pricing"
                    underline="none"
                    target="_blank"
                  >
                    Plans &amp; Pricing page
                  </Link>
                  .
                </Typography>
              </>
            ) : (
              <>
                <Typography className={classes.description}>{description}</Typography>
                {marketplaceRequestAccess && (
                  <Typography className={classes.description}>
                    {isPubHub && 'If you do not have a Marketplace account '}
                    {!isPubHub && 'If you are a new user and would like to access the Marketplace '}
                    <button className={classes.btnLink} onClick={handleRequestAccess}>
                      please request access
                    </button>{' '}
                    here.
                  </Typography>
                )}
              </>
            )}
            {emailContact && emailContact.length > 0 && (
              <Typography>
                For assistance or to request access please contact <a href={`mailto:${emailContact}`}>{emailContact}</a>
                .
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Layout
