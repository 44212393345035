/**
 * @file Register View
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { useEffect, useState, useMemo, useCallback } from 'react'
import cx from 'classnames'
import { Auth } from '@aws-amplify/auth'
// External
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  LinearProgress,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import isEmpty from 'lodash/isEmpty'
import { getConsumerById, consumeUserInvite } from './api'
import propTypes from './propTypes'

const useStyles = makeStyles((theme) => ({
  register: {
    display: 'flex',
    flexDirection: 'column',
  },
  createAccount: {
    margin: theme.spacing(1, 0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
}))

const Register = (props) => {
  const classes = useStyles(props)
  const { authState, inviteToken, onStateChange: handleStateChange, marketplaceId } = props
  const phoneFormatter = new AsYouType('US')

  const [isInitLoading, setInitLoading] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [formErrors, setFormErrors] = useState({})
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    optIn: false,
  })

  // Terms of Service
  const [tos, setTos] = useState({
    tosCheck: false,
    tosViewed: false,
    privacyViewed: false,
    error: false,
  })
  const marketplacePath =
    marketplaceId === 'dmlmarketplace' ? 'dml.market' : 'brandedcontentproject.distributedmedialab.com'

  const { optIn } = data

  useEffect(() => {
    if (inviteToken && inviteToken.length > 0) {
      const fetchData = async () => {
        try {
          const { data } = await getConsumerById(inviteToken)
          if (!data || isEmpty(data?.data)) {
            setError('Oops, the invite is either expired or does not exist.')
          } else if (data?.data?.attributes?.consumed) {
            setError('Oops, it looks like this invitation has already been consumed.')
          } else {
            setData({
              ...data?.data.attributes,
              optIn: false,
            })
          }
        } catch (e) {
          console.error(e)
          setError('Oops, there was an issue on our end looking up your invite.')
        } finally {
          setInitLoading(false)
        }
      }

      fetchData()
    }
  }, [inviteToken, authState])

  const handleBlur = ({ target: { name, value } }) => {
    if (name === 'phoneNumber' && !parsePhoneNumber(`+1${value}`)) {
      setFormErrors({ ...formErrors, [name]: true })
    } else if (name === 'password' && value.length < 8) {
      setFormErrors({ ...formErrors, [name]: true })
    } else if (name === 'confirmPassword' && value !== data.password) {
      setFormErrors({ ...formErrors, [name]: true })
    } else {
      setFormErrors({ ...formErrors, [name]: false })
    }
  }

  const hasFormError = useMemo(
    () => formErrors.phoneNumber || formErrors.password || formErrors.confirmPassword,
    [formErrors],
  )
  const hasEmptyData = useMemo(
    () => isEmpty(data.phoneNumber) || isEmpty(data.confirmPassword) || isEmpty(data.password),
    [data],
  )

  const optIns = marketplaceId
    ? JSON.stringify({
        [marketplaceId]: {
          enabled: optIn,
          optedInAt: optIn ? new Date().toISOString() : '',
          lastAskedAt: new Date().toISOString(),
        },
      })
    : ''

  const handleSignUp = async () => {
    setLoading(true)
    try {
      const phoneNumber = parsePhoneNumber(`+1${data.phoneNumber}`)
      const signupRes = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          name: `${data.firstName} ${data.lastName}`,
          given_name: data.firstName,
          family_name: data.lastName,
          email: data.email,
          phone_number: phoneNumber.number,
          ...(marketplaceId ? { 'custom:optIns': optIns } : {}),
        },
      })
      await consumeUserInvite(inviteToken, signupRes.userSub)
      handleStateChange('confirmSignUp', {
        username: signupRes.user.username,
      })
    } catch (e) {
      console.error(e)
      setError('Oops, there was an issue on our end registering your account.')
    } finally {
      setLoading(false)
    }
  }

  const handleEnterKey = (event) => {
    if (event.key === 'Enter' && !hasFormError && !hasEmptyData) {
      handleSignUp()
    }
  }

  const handleToSChange = useCallback(({ target: { name } }) => {
    setTos((tos) => {
      let val = name.includes('Viewed') ? true : !tos[name]
      // Require user to view ToS before checking the box
      let error = false
      if (name === 'tosCheck' && (!tos.tosViewed || !tos.privacyViewed)) {
        error = true
        val = false
      }
      return {
        ...tos,
        [name]: val,
        error,
      }
    })
  }, [])

  const handleInputChange = ({ target: { value, name } }) => {
    if (name === 'phoneNumber') {
      phoneFormatter.reset()
      value = phoneFormatter.input(value)
    }
    if (name === 'optIn') {
      value = !optIn
    }
    setData({ ...data, [name]: value })
  }

  const handleRouteToLogin = () => {
    window.location.search = ''
    handleStateChange('signIn', {})
  }

  const allTextFieldProps = {
    variant: 'outlined',
    onChange: handleInputChange,
    onKeyPress: handleEnterKey,
    onBlur: handleBlur,
    size: 'small',
    fullWidth: true,
    required: true,
    margin: 'dense',
  }

  const submitIsDisabled = isLoading || hasFormError || hasEmptyData || (!!marketplaceId && !tos.tosCheck)

  return (
    <div className={cx(classes.register, 'animate__animated animate__fadeIn animate__faster')}>
      {!!error && (
        <Alert className={cx(classes.alert, 'animate__animated animate__headShake')} severity="error">
          {error}
        </Alert>
      )}
      {!isInitLoading && !error && (
        <>
          <TextField
            {...allTextFieldProps}
            id="dml-register-email"
            key="dml-register-email"
            name="email"
            label="Email"
            disabled
            value={data.email}
          />
          <TextField
            {...allTextFieldProps}
            id="dml-register-first"
            key="dml-register-first"
            name="firstName"
            label="First Name"
            disabled
            value={data.firstName}
          />
          <TextField
            {...allTextFieldProps}
            id="dml-register-last"
            key="dml-register-last"
            name="lastName"
            label="Last Name"
            disabled
            value={data.lastName}
          />
          <TextField
            {...allTextFieldProps}
            id="dml-register-phone-number"
            key="dml-register-phone-number"
            name="phoneNumber"
            label="Cell Phone Number"
            error={formErrors.phoneNumber}
            helperText="Used for account verification and password reset"
            value={data.phoneNumber || ''}
            disabled={isLoading}
          />
          <TextField
            {...allTextFieldProps}
            id="dml-register-password"
            key="dml-register-password"
            name="password"
            type="password"
            label="Password"
            autoComplete="off"
            helperText="Password must be at least 8 characters."
            error={formErrors.password}
            value={data.password}
            disabled={isLoading}
          />
          <TextField
            {...allTextFieldProps}
            id="dml-register-confirm-password"
            key="dml-register-confirm-password"
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            autoComplete="off"
            error={formErrors.confirmPassword}
            value={data.confirmPassword}
            disabled={isLoading}
          />
          {!!marketplaceId && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optIn}
                    name="optIn"
                    onChange={handleInputChange}
                    color="primary"
                    disabled={isLoading}
                  />
                }
                label="I consent to receive important updates and announcements via email"
              />
              <FormControl required error={tos.error}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tos.tosCheck}
                      name="tosCheck"
                      onChange={handleToSChange}
                      color="primary"
                      disabled={isLoading}
                    />
                  }
                  label={
                    <>
                      <span>* I have read and accepted the </span>
                      <a
                        href={`https://${marketplacePath}/terms-of-service`}
                        target="_blank"
                        rel="noreferrer"
                        name="tosViewed"
                        onClick={handleToSChange}
                      >
                        Terms of Service
                      </a>{' '}
                      and the{' '}
                      <a
                        href={`https://${marketplacePath}/privacy-policy`}
                        target="_blank"
                        rel="noreferrer"
                        name="privacyViewed"
                        onClick={handleToSChange}
                      >
                        Privacy Policy
                      </a>
                      {tos.error && (
                        <FormHelperText>
                          You must view the Terms of Service and Privacy Policy before accepting
                        </FormHelperText>
                      )}
                    </>
                  }
                />
              </FormControl>
            </>
          )}
          <Button
            onClick={handleSignUp}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            className={classes.createAccount}
            disabled={submitIsDisabled}
          >
            Create Account
          </Button>
        </>
      )}
      <Button onClick={handleRouteToLogin} variant="outlined" fullWidth disabled={isLoading}>
        Back to Sign In
      </Button>
      {(isLoading || isInitLoading) && (
        <LinearProgress className={cx(classes.progress, 'animate__animated animate__fadeIn animate__faster')} />
      )}
    </div>
  )
}

Register.propTypes = propTypes

export default Register
