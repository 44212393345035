/**
 * @file Forgot Password Login View
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { useState, useMemo } from 'react'
import cx from 'classnames'
import { TextField, Typography, Button, LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Auth } from '@aws-amplify/auth'
import { makeStyles } from '@material-ui/styles'
import isEmail from 'validator/lib/isEmail'
import get from 'lodash/get'
import toLower from 'lodash/toLower'
import propTypes from './propTypes'

const useStyles = makeStyles((theme) => ({
  forgotPassword: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacingUnder: {
    paddingBottom: theme.spacing(1),
  },
  topText: {
    paddingBottom: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  actionBtn: {
    margin: theme.spacing(1, 0),
  },
  resendCode: {
    background: 'none',
    border: 'none',
    outline: 'none',
    color: theme.palette.primary.main,
    fontSize: 'inherit',
    marginLeft: theme.spacing(0.5),
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  progress: {
    marginTop: theme.spacing(1),
  },
}))

const ForgotPassword = (props) => {
  const { onStateChange: handleStateChange, authData, authState } = props

  const isPasswordReset = authState === 'passwordReset'

  const classes = useStyles(props)
  const [codeSent, setCodeSent] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    email: get(authData, 'username', ''),
    code: '',
    password: '',
    confirmPassword: '',
  })
  const [formErrors, setFormErrors] = useState({
    email: false,
    code: false,
    password: false,
    confirmPassword: false,
  })
  const [errorMessage, setErrorMessage] = useState(null)

  const { email, code, password, confirmPassword } = formData
  const {
    email: emailError,
    code: codeError,
    password: passwordError,
    confirmPassword: confirmPasswordError,
  } = formErrors

  const handleChange = ({ target: { value, name } }) =>
    setFormData({
      ...formData,
      [name]: name === 'email' ? toLower(value) : value,
    })

  const handleBlur = ({ target: { value, name } }) => {
    if (name === 'email' && (value === '' || !isEmail(value))) {
      setFormErrors({ ...formErrors, email: true })
    } else if (name === 'email') {
      setFormErrors({ ...formErrors, email: false })
    }

    if (name === 'password' && value.length < 8) {
      setFormErrors({ ...formErrors, password: true })
    } else if (name === 'password') {
      setFormErrors({ ...formErrors, password: false })
    }

    if (name === 'confirmPassword' && value !== password) {
      setFormErrors({ ...formErrors, confirmPassword: true })
    } else if (name === 'confirmPassword') {
      setFormErrors({ ...formErrors, confirmPassword: false })
    }
  }

  const handleForgotPassword = async () => {
    setErrorMessage(null)
    setLoading(true)
    try {
      await Auth.forgotPassword(email)
      setCodeSent(true)
    } catch (e) {
      setErrorMessage(e.message)
      if (e.code === 'UserNotFoundException') {
        setErrorMessage('An account matching that email was not found.')
      } else {
        setErrorMessage(e.message)
      }
    }
    setLoading(false)
  }

  const handleChangePassword = async () => {
    setErrorMessage(null)
    setLoading(true)
    try {
      await Auth.forgotPasswordSubmit(email, code, password)
      handleStateChange('passwordUpdated', { username: email })
    } catch (e) {
      setErrorMessage(e.message)
    }
    setLoading(false)
  }

  const handleSubmit = () => {
    if (codeSent) {
      handleChangePassword()
    } else {
      handleForgotPassword()
    }
  }

  const handleBackToSignIn = () => {
    handleStateChange('signIn', { username: email })
  }

  const submitDisabled = useMemo(
    () =>
      codeSent
        ? isLoading ||
          formErrors.email ||
          formData.email.length < 1 ||
          formErrors.code ||
          formData.code.length < 1 ||
          formErrors.password ||
          formData.password.length < 1 ||
          formErrors.confirmPassword ||
          formData.confirmPassword.length < 1
        : isLoading || formErrors.email || formData.email.length < 1,
    [formErrors, formData, isLoading, codeSent],
  )

  return (
    <div className="'animate__animated animate__fadeIn animate__faster'">
      {!!errorMessage && (
        <Alert className={cx(classes.alert, 'animate__animated animate__headShake')} severity="error">
          {errorMessage}
        </Alert>
      )}
      {!errorMessage && isPasswordReset && (
        <Alert className={classes.alert} severity="info" icon={false}>
          You must reset your password in-order to login.
        </Alert>
      )}
      <Typography className={classes.topText}>A confirmation code will be sent to your mobile device.</Typography>
      <TextField
        label="Email"
        variant="outlined"
        type="email"
        name="email"
        value={email}
        onChange={handleChange}
        fullWidth
        className={classes.spacingUnder}
        onBlur={handleBlur}
        error={emailError}
        disabled={codeSent || isLoading}
        size="small"
      />
      {codeSent && (
        <>
          <TextField
            label="Code"
            variant="outlined"
            name="code"
            value={code}
            onChange={handleChange}
            fullWidth
            className={classes.spacingUnder}
            onBlur={handleBlur}
            error={codeError}
            disabled={isLoading}
            size="small"
            helperText={
              <span>
                Didn't receive a verification code?
                <button className={classes.resendCode} onClick={handleForgotPassword}>
                  Resend Code
                </button>
              </span>
            }
          />
          <TextField
            label="New Password"
            variant="outlined"
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
            fullWidth
            className={classes.spacingUnder}
            onBlur={handleBlur}
            error={passwordError}
            helperText="Password must be at least 8 characters."
            disabled={isLoading}
            size="small"
          />
          <TextField
            label="Confirm New Password"
            variant="outlined"
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleChange}
            fullWidth
            className={classes.spacingUnder}
            onBlur={handleBlur}
            error={confirmPasswordError}
            disabled={isLoading}
            size="small"
          />
        </>
      )}
      <Button
        type="submit"
        color="primary"
        variant="contained"
        onClick={handleSubmit}
        fullWidth
        disabled={submitDisabled}
        className={classes.actionBtn}
      >
        {codeSent ? 'Change Password' : 'Get Code'}
      </Button>
      <Button variant="outlined" fullWidth onClick={handleBackToSignIn}>
        Back To Sign In
      </Button>
      {isLoading && (
        <LinearProgress className={cx(classes.progress, 'animate__animated animate__fadeIn animate__faster')} />
      )}
    </div>
  )
}

ForgotPassword.propTypes = propTypes

export default ForgotPassword
