/**
 * @file Require New Password Login View
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { useState } from 'react'
import cx from 'classnames'
import { Auth } from '@aws-amplify/auth'
import { I18n, isEmpty } from '@aws-amplify/core'
import Alert from '@material-ui/lab/Alert'
import { TextField, Button, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import propTypes from './propTypes'

const useStyles = makeStyles((theme) => ({
  requireNewPassword: {
    display: 'flex',
    flexDirection: 'column',
  },
  confirmButton: {
    margin: theme.spacing(1, 0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
}))

const RequireNewPassword = (props) => {
  const { authData, onStateChange: handleStateChange } = props

  const {
    challengeParam: {
      requiredAttributes,
      userAttributes: { email },
    },
  } = authData

  const classes = useStyles(props)
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [formErrors, setFormErrors] = useState({})

  const handleBackToSignIn = () => {
    handleStateChange('signIn', { username: email })
  }

  const handleChange = ({ target: { name, value } }) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleBlur = ({ target: { name, value, required } }) => {
    if (required && (!value || value.length < 1)) {
      setFormErrors({ ...formErrors, [name]: true })
    } else if (name === 'password' && value.length < 8) {
      setFormErrors({ ...formErrors, [name]: true })
    } else if (name === 'confirmPassword' && value !== formData.password) {
      setFormErrors({ ...formErrors, [name]: true })
    } else {
      setFormErrors({ ...formErrors, [name]: false })
    }
  }

  const checkContact = async (user) => {
    const verifiedContactRes = await Auth.verifiedContact(user)
    if (!isEmpty(verifiedContactRes.verified)) {
      handleStateChange('signedIn', user)
    } else {
      handleStateChange('verifyContact', Object.assign(user, verifiedContactRes))
    }
  }

  const handleCompleteNewPassword = async () => {
    setLoading(true)
    try {
      const attrs = objectWithProperties(formData, requiredAttributes)
      const user = await Auth.completeNewPassword(authData, formData.password, attrs)
      if (user.challengeName === 'SMS_MFA') {
        handleStateChange('confirmSignIn', { user })
      } else if (user.challengeName === 'MFA_SETUP') {
        handleStateChange('TOTPSetup', user)
      } else {
        checkContact(user)
      }
      await checkContact(user)
    } catch (e) {
      console.error(e)
      setError(e)
    }
  }

  return (
    <div className={cx(classes.requireNewPassword, 'animate__animated animate__fadeIn animate__faster')}>
      {!!error && (
        <Alert className={cx(classes.alert, 'animate__animated animate__headShake')} severity="error">
          {error}
        </Alert>
      )}
      {requiredAttributes.map((attribute) => (
        <TextField
          label={I18n.get(convertToPlaceholder(attribute))}
          variant="outlined"
          key={attribute}
          name={attribute}
          value={formData[attribute] || ''}
          fullWidth
          onChange={handleChange}
          margin="dense"
          required
          onBlur={handleBlur}
          error={formErrors[attribute]}
          disabled={isLoading}
          size="small"
        />
      ))}
      <TextField
        label="New Password"
        variant="outlined"
        key="password"
        name="password"
        value={formData.password || ''}
        fullWidth
        onChange={handleChange}
        margin="dense"
        required
        helperText="Password must be at least 8 characters."
        onBlur={handleBlur}
        error={formErrors.password}
        disabled={isLoading}
        size="small"
        type="password"
      />
      <TextField
        label="Confirm New Password"
        variant="outlined"
        key="confirmPassword"
        name="confirmPassword"
        value={formData.confirmPassword || ''}
        fullWidth
        onChange={handleChange}
        margin="dense"
        required
        onBlur={handleBlur}
        error={formErrors.password}
        disabled={isLoading}
        size="small"
        type="password"
      />
      <Button
        type="submit"
        color="primary"
        variant="contained"
        onClick={handleCompleteNewPassword}
        fullWidth
        disabled={isLoading}
        className={classes.confirmButton}
      >
        Update Account
      </Button>
      <Button variant="outlined" fullWidth onClick={handleBackToSignIn} disabled={isLoading}>
        Back To Sign In
      </Button>
      {isLoading && (
        <LinearProgress className={cx(classes.progress, 'animate__animated animate__fadeIn animate__faster')} />
      )}
    </div>
  )
}

function convertToPlaceholder(str) {
  return str
    .split('_')
    .map((part) => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
    .join(' ')
}

function objectWithProperties(obj, keys) {
  const target = {}
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      continue
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue
    }
    target[key] = obj[key]
  }
  return target
}

RequireNewPassword.propTypes = propTypes

export default RequireNewPassword
