import { FC } from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

export const NewItemButton: FC<Props> = ({ to, buttonText }) => {
  return (
    <Link to={to}>
      <Button variant="contained" color="primary">
        {buttonText}
      </Button>
    </Link>
  )
}

interface Props {
  to: string
  buttonText: string
}

const Link = styled(RouterLink)`
  text-decoration: none;
`
