import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useMutation } from 'react-query'
import cx from 'classnames'
import { Button, TextField, LinearProgress, Typography, MenuItem } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/styles'
import { requestAccess } from '@dmlab/ui-api-request-access'

import propTypes from './propTypes'

const useStyles = makeStyles((theme) => ({
  register: {
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(1, 0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
  successText: {
    marginBottom: theme.spacing(2),
  },
}))

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

const validateUrl = (value) => {
  return /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/i.test(value)
}

const accountPlanOptions = [
  {
    value: 'free',
    label: 'Free',
  },
  {
    value: 'basic',
    label: 'Basic',
  },
  {
    value: 'growth',
    label: 'Growth',
  },
  {
    value: 'plus',
    label: 'Plus',
  },
  {
    value: 'network',
    label: 'Network',
  },
]

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const selectedPlan = urlParams.get('plan')
const isSelectedPlanValid = accountPlanOptions.some(({ value }) => value === selectedPlan)

const MarketplaceRequestAccess = (props) => {
  const classes = useStyles(props)
  const { onStateChange: handleStateChange } = props

  const [formErrors, setFormErrors] = useState({})
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    publisherName: '',
    publisherWebsite: '',
    accountPlan: '',
  })

  const canSubmit = useMemo(
    () =>
      !Object.values(formErrors).some((v) => !!v) &&
      data.firstName &&
      data.lastName &&
      data.email &&
      data.publisherName &&
      data.publisherWebsite,
    [data, formErrors],
  )

  const handleChange = useCallback(({ target: { name, value } }) => {
    setData((c) => ({
      ...c,
      [name]: value,
    }))
  }, [])

  const handleBlur = ({ target: { name, value } }) => {
    if (name === 'email' && !validateEmail(value)) {
      setFormErrors({ ...formErrors, [name]: true })
    } else if (name === 'publisherWebsite' && !validateUrl(value)) {
      setFormErrors({ ...formErrors, [name]: true })
    } else if (name === 'firstName' && value.length < 1) {
      setFormErrors({ ...formErrors, [name]: true })
    } else if (name === 'lastName' && value.length < 1) {
      setFormErrors({ ...formErrors, [name]: true })
    } else if (name === 'publisherName' && value.length < 1) {
      setFormErrors({ ...formErrors, [name]: true })
    } else {
      setFormErrors({ ...formErrors, [name]: false })
    }
  }

  const allTextFieldProps = {
    variant: 'outlined',
    onChange: handleChange,
    onBlur: handleBlur,
    size: 'small',
    fullWidth: true,
    required: true,
    margin: 'dense',
  }

  const { mutate, isError, isSuccess, isLoading } = useMutation((d) =>
    requestAccess({
      email: d.email,
      firstName: d.firstName,
      lastName: d.lastName,
      publisherName: d.publisherName,
      publisherWebsite: d.publisherWebsite,
      accountPlan: d.accountPlan,
      redirect: window.location.href,
    }),
  )

  const handleSubmit = useCallback(() => {
    mutate(data)
  }, [data, mutate])

  const handleRouteToLogin = () => {
    handleStateChange('signIn', {})
  }

  useEffect(() => {
    if (isSelectedPlanValid) {
      setData({
        accountPlan: selectedPlan,
      })
    }
  }, [])

  return (
    <div className={cx(classes.register, 'animate__animated animate__fadeIn animate__faster')}>
      {isError && (
        <Alert className={cx(classes.alert, 'animate__animated animate__headShake')} severity="error">
          Oops, there was an error. Please get in touch with DML support if you continue to receive this error.
        </Alert>
      )}
      {!isSuccess && (
        <>
          <TextField
            {...allTextFieldProps}
            id="dml-access-request-first"
            name="firstName"
            label="First Name"
            error={formErrors.firstName}
            disabled={isLoading}
            value={data.firstName}
          />
          <TextField
            {...allTextFieldProps}
            id="dml-access-request-last"
            name="lastName"
            label="Last Name"
            error={formErrors.lastName}
            disabled={isLoading}
            value={data.lastName}
          />
          <TextField
            {...allTextFieldProps}
            id="dml-access-request-email"
            name="email"
            label="Email"
            error={formErrors.email}
            disabled={isLoading}
            value={data.email}
          />
          <TextField
            {...allTextFieldProps}
            id="dml-access-request-publisher-name"
            name="publisherName"
            label="Company Name"
            error={formErrors.publisherName}
            disabled={isLoading}
            value={data.publisherName}
          />
          <TextField
            {...allTextFieldProps}
            id="dml-access-request-publisher-website"
            name="publisherWebsite"
            label="Company Website"
            error={formErrors.publisherWebsite}
            helperText="Please provide the primary website if you have more than one"
            disabled={isLoading}
            value={data.publisherWebsite}
          />
          <TextField
            {...allTextFieldProps}
            select
            id="dml-access-request-account-plan"
            name="accountPlan"
            label="Account Plan"
            disabled={isLoading}
            value={data.accountPlan}
          >
            {accountPlanOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={isLoading || !canSubmit}
            className={classes.submit}
          >
            Request Access
          </Button>
        </>
      )}
      {isSuccess && (
        <Typography className={classes.successText}>
          You successfully requested access to the Distributed Media Lab Marketplace. You should receive a confirmation
          email soon.
        </Typography>
      )}
      <Button onClick={handleRouteToLogin} variant="outlined" fullWidth>
        Back to Sign In
      </Button>
      {isLoading && (
        <LinearProgress className={cx(classes.progress, 'animate__animated animate__fadeIn animate__faster')} />
      )}
    </div>
  )
}

MarketplaceRequestAccess.propTypes = propTypes

export default MarketplaceRequestAccess
