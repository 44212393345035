/**
 * @file Sign In Login View
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { useState, useMemo } from 'react'
import cx from 'classnames'
import { TextField, Button, LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Auth } from '@aws-amplify/auth'
import { makeStyles } from '@material-ui/styles'
import isEmail from 'validator/lib/isEmail'
import get from 'lodash/get'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'
import propTypes from './propTypes'

const useStyles = makeStyles((theme) => ({
  signIn: {
    display: 'flex',
    flexDirection: 'column',
  },
  forgotPassword: {
    margin: theme.spacing(1, 0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  actionBtn: {
    marginTop: theme.spacing(1),
  },
  progress: {
    marginTop: theme.spacing(1),
  },
}))

const SignIn = (props) => {
  const { onStateChange: handleStateChange, authState, authData, useEmail } = props
  const classes = useStyles(props)

  const [isLoading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    username: get(authData, 'username', ''),
    password: '',
  })
  const [formErrors, setFormErrors] = useState({ username: false, password: false })
  const [errorMessage, setErrorMessage] = useState(null)

  const { username, password } = formData

  const handleChange = ({ target: { value, name, type } }) =>
    setFormData({
      ...formData,
      [name]: type === 'email' ? toLower(value) : value,
    })

  const hasFormError = useMemo(() => formErrors.username || formErrors.password, [formErrors])
  const hasEmptyData = useMemo(() => isEmpty(formData.username) || isEmpty(formData.password), [formData])

  const handleBlur = ({ target: { value, name } }) => {
    if (useEmail && name === 'username' && (username === '' || !isEmail(value))) {
      setFormErrors({ ...formErrors, [name]: true })
    } else if (name === 'password' && password.length < 3) {
      // TODO: password length
      setFormErrors({ ...formErrors, [name]: true })
    } else {
      setFormErrors({ ...formErrors, [name]: false })
    }
  }

  const handleForgotPassword = () => {
    handleStateChange('forgotPassword', {
      ...(isEmail(username) ? { username } : {}),
    })
  }

  const handleEnterKey = (event) => {
    if (event.key === 'Enter' && !hasFormError && !hasEmptyData) {
      handleSignIn()
    }
  }

  const handleSignIn = async () => {
    setErrorMessage(null)
    setLoading(true)
    try {
      const signInRes = await Auth.signIn(username, password)
      if (signInRes.challengeName === 'NEW_PASSWORD_REQUIRED') {
        handleStateChange('requireNewPassword', signInRes)
      } else if (['SMS_MFA', 'SOFTWARE_TOKEN_MFA'].includes(signInRes.challengeName)) {
        handleStateChange('confirmSignIn', { user: signInRes, username })
      } else {
        handleStateChange('signedIn', signInRes)
      }
    } catch (e) {
      setFormData({ ...formData, password: '' })
      if (['UserNotFoundException', 'NotAuthorizedException'].includes(e.code)) {
        setErrorMessage('Incorrect username or password.')
      } else if (e.code === 'UserNotConfirmedException') {
        handleStateChange('confirmSignUp', { username })
      } else if (e.code === 'PasswordResetRequiredException') {
        handleStateChange('passwordReset', { username })
      } else {
        setErrorMessage(e.message)
      }
    }
    setLoading(false)
  }

  return (
    <div className={cx(classes.signIn, 'animate__animated animate__fadeIn animate__faster')}>
      {!!errorMessage && (
        <Alert className={cx(classes.alert, 'animate__animated animate__headShake')} severity="error">
          {errorMessage}
        </Alert>
      )}
      {['signedOut', 'confirmSignUp', 'passwordUpdated'].includes(authState) && !errorMessage && (
        <Alert severity="success" className={classes.alert}>
          {authState === 'signedOut' && "You've successfully been signed out."}
          {authState === 'confirmSignUp' && 'Your account creation was successful.'}
          {authState === 'passwordUpdated' && 'Your password change was successful.'}
        </Alert>
      )}
      <TextField
        label={useEmail ? 'Email' : 'Username'}
        variant="outlined"
        type={useEmail ? 'email' : 'text'}
        name="username"
        value={username}
        onChange={handleChange}
        fullWidth
        margin="dense"
        onKeyPress={handleEnterKey}
        onBlur={handleBlur}
        disabled={isLoading}
        size="small"
      />
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        name="password"
        value={password}
        onChange={handleChange}
        onKeyPress={handleEnterKey}
        fullWidth
        margin="dense"
        onBlur={handleBlur}
        disabled={isLoading}
        size="small"
      />
      <Button
        variant="text"
        size="small"
        onClick={handleForgotPassword}
        className={classes.forgotPassword}
        disabled={isLoading}
      >
        Forgot Your Password?
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        onClick={handleSignIn}
        fullWidth
        disabled={isLoading || hasFormError || hasEmptyData}
        className={classes.actionBtn}
      >
        Login
      </Button>
      {isLoading && (
        <LinearProgress className={cx(classes.progress, 'animate__animated animate__fadeIn animate__faster')} />
      )}
    </div>
  )
}

SignIn.propTypes = propTypes

export default SignIn
